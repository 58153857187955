@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap");

// Fonts
$montserrat: "Montserrat", sans-serif;

// Override default variables before the import
$font-family-sans-serif: "Montserrat";
$font-size-base: 0.9rem;
$h1-font-size: 2em;
$h3-font-size: 1.2em;
$body-bg: #f5f5f5;
$light: #f5f5f5;
$dark: #616161;
$info: #e8e8e8;
$muted: #999;
$dark-mid: #323232;
$dark-strong: #212121;
$primary: #4056a1;
$text-muted: $muted;
$body-color: $muted;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

* {
  -webkit-transition: background-color 0.5s ease;
  -moz-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
}

.dark body {
  background-color: $dark-mid !important;
  color: $light;
}

.dark .bg-light {
  background-color: $dark-strong !important;
}

.dark .text-muted {
  color: $light !important;
}
.dark .text-dark {
  color: $light !important;
}

.navbar {
  padding-bottom: 30px;
}

h1 {
  margin: 0;
  font-weight: 600;
  font-size: 1.4rem;
}

h2 {
  font-size: 1.2rem;
}

h3 {
  font-weight: 600;
  font-size: 1.4rem;
}

h4 {
  font-weight: 600;
  font-size: 1.1rem;
}

a,
p {
  margin: 0;
  text-align: center;
  font-size: 0.9rem;
}

@include media-breakpoint-up(sm) {
  a,
  p {
    font-size: 1.5rem;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.4rem;
  }
  h3 {
    font-size: 1.8rem;
  }
  h4 {
    font-size: 2rem;
  }
}

@include media-breakpoint-up(lg) {
  a,
  p {
    font-size: 1.6rem;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 2.3rem;
  }
  h4 {
    font-size: 1.8rem;
  }
}

/*React Toggle*/
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: $white;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $white;
}

.react-toggle-track-check {
  position: absolute;
  line-height: 10px;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  line-height: 10px;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: $light;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: $primary;
  background-color: $dark;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px $yellow;
  -moz-box-shadow: 0px 0px 3px 2px $yellow;
  box-shadow: 0px 0px 2px 3px $yellow;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px $yellow;
  -moz-box-shadow: 0px 0px 5px 5px $yellow;
  box-shadow: 0px 0px 5px 5px $yellow;
}
