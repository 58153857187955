@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

#mockup-phone {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 240px;
  height: 240px;
  width: 100%;
  margin-top: 190px;
}

@include media-breakpoint-up(sm) {
  #mockup-phone {
    margin-top: 200px;
  }
}

@include media-breakpoint-up(md) {
  #mockup-phone {
    background-position: center;
    background-size: auto 350px;
    height: 350px;
  }
}

@include media-breakpoint-up(lg) {
  #mockup-phone {
    background-position: center;
    background-size: auto 520px;
    height: 520px;
    margin-top: 0;
  }
}
