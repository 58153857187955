@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";
/* 
@include media-breakpoint-up(md) {
  .image-card {
    margin-bottom: -25%;
  }
} */

.image {
  max-width: 100%;
  height: auto;
}

@include media-breakpoint-up(lg) {
  .image-card {
    margin-bottom: -10%;
  }

  .image {
    max-width: 60%;
    height: auto;
  }

  .big-image {
    max-width: 55%;
    height: auto;
  }

  .text-description {
    font-size: 1.2rem;
    max-width: 85%;
  }
  .title-card {
    max-width: 75%;
  }
}

@include media-breakpoint-up(xl) {
  .image {
    max-width: 50%;
  }

  .big-image {
    max-width: 45%;
    height: auto;
  }
  .image-card {
    margin-bottom: -7%;
  }
}
