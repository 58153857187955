@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.curve-container {
  min-height: 270px;
}

@include media-breakpoint-up(md) {
  .curve-container {
    min-height: 370px;
  }
}

@include media-breakpoint-up(lg) {
  .curve-container {
    min-height: 200px;
  }
}
