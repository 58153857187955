@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.headline {
  p {
    margin-bottom: 0px;
  }
  text-align: left;
  padding: 0px 15px 0px 15px;

  h1 {
    margin-bottom: 10px;
  }
}

@include media-breakpoint-up(md) {
  .download-button {
    margin-top: 5px;
    width: 200px;
  }
}

@include media-breakpoint-up(lg) {
  h1 {
    max-width: 70%;
  }
}
